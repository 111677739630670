<template>
  <div class="all">
    <Navbar class="tablet-hidden mobile-hidden" />
    <div class="page">
      <Header />

      <div v-show="loading" class="flex-center mt-3">
        <div class="loading2"></div>
      </div>
      <transition
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <div v-show="loadingImageCrop" class="bg-cover flex-center">
          <div class="loading-spinner"></div>
        </div>
      </transition>
      <div class="home container">
        <div class="grid">
          <div v-show="!loading" class="info-holder mt-3-mobile">
            <transition enter-active-class="animated zoomIn">
              <div
                v-show="!loading"
                class="user-image"
                :style="`background-image:url(${user.image});`"
              ></div>
            </transition>
            <transition enter-active-class="animated fadeInLeft">
              <p v-show="!loading" style="animation-delay: 300ms">
                {{ t("Você só pode trocar sua foto uma vez a cada 7 dias.") }}
                <br /><br />
                {{ t("Última troca em") }}:
                <b v-if="!loading && user.imageLastModified">{{
                  user.imageLastModified | moment("DD/MM/YYYY")
                }}</b>
                <b v-else>Nunca</b>
              </p>
            </transition>
            <transition enter-active-class="animated fadeInUp">
              <div
                v-show="!loading"
                class="btn-holder"
                style="animation-delay: 600ms"
              >
                <div class="btn border">
                  <input
                    class="invisible-input"
                    @change="processFile($event)"
                    type="file"
                    ref="myFiles"
                    accept="image/*"
                  />
                  <font-awesome-icon :icon="['fas', 'camera']" />
                  <span>{{ t("Escolher nova foto") }}</span>
                </div>
              </div>
            </transition>

            <transition enter-active-class="animated fadeInDown">
              <div
                v-show="!loading"
                class="input-item mt-2"
                style="animation-delay: 800ms"
              >
                <span
                  >{{ t("Nome profissional / artístico / comercial") }} *</span
                >
                <input type="text" v-model="user.professionalName" />
              </div>
            </transition>

            <transition enter-active-class="animated fadeInDown">
              <div
                v-show="!loading"
                class="input-item mt-2"
                style="animation-delay: 800ms"
              >
                <span>Instagram *</span>
                <input
                  type="text"
                  v-model="user.instagram"
                  placeholder="@seuPerfil"
                />
              </div>
            </transition>

            <transition enter-active-class="animated fadeIn">
              <p
                v-show="warningMessage != ''"
                class="warning mt-2"
                style="position: relative; display: block"
              >
                {{ warningMessage }}
              </p>
            </transition>

            <transition enter-active-class="animated fadeInUp">
              <div
                v-show="!loading"
                class="btn-holder"
                style="animation-delay: 1000ms"
              >
                <div @click="putUser" class="btn">
                  <span v-show="!saving">{{ t("Salvar alterações") }}</span>
                  <font-awesome-icon
                    v-show="!saving"
                    :icon="['fas', 'check']"
                  />
                  <div v-show="saving" class="loading2 white"></div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <transition leave-active-class="animated fadeOut">
      <ImageCrop
        v-show="selectedImage != ''"
        :imageData="selectedImage"
        :cropSettings="imageCropSettings"
        @photoCropConfirm="photoCropConfirmed"
        @photoCropCancel="photoCropCanceled"
      ></ImageCrop>
    </transition>
  </div>
</template>

<script>
import http from "@/http";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import authentication from "../util/authentication";
import ImageCrop from "../components/ImageCrop";
export default {
  components: {
    Navbar,
    Header,
    ImageCrop,
  },
  data() {
    return {
      loading: true,
      loadingImageCrop: false,
      saving: false,
      user: {
        professionalName: "",
        image: "",
        imageLastModified: "",
      },
      warningMessage: "",
      selectedImage: "",
      imageCropSettings: {
        aspectRatio: 1,
        shape: "circle",
      },
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    putUser() {
      if (!this.user.image) {
        this.warningMessage =
          this.$translate.locale["Por favor, selecione uma nova foto"];
        return;
      }
      if (!this.user.professionalName) {
        this.warningMessage =
          this.$translate.locale[
            "Por favor, informe seu nome profissional / artístico / comercial"
          ];
        return;
      }
      if (!this.user.instagram) {
        this.warningMessage =
          this.$translate.locale["Por favor, informe o seu Instagram"];
        return;
      }

      this.warningMessage = "";
      this.saving = true;
      http
        .put("user", this.user, authentication.getHeaders())
        .then((response) => {
          if (response.status === 200) {
            this.user = response.data;
            let objUser = JSON.parse(localStorage.user);
            objUser.info = this.user;
            localStorage.user = JSON.stringify(objUser);

            this.warningMessage =
              this.$translate.locale["Dados atualizados com sucesso!"];
          }
        })
        .catch(() => {
          this.warningMessage =
            this.$translate.locale[
              "A imagem só pode ser alterada a cada 7 dias"
            ];
        })
        .finally(() => {
          this.loading = false;
          this.saving = false;
        });
    },
    photoCropConfirmed(dataImage) {
      this.user.image = dataImage;

      this.selectedImage = "";
    },
    photoCropCanceled() {
      this.selectedImage = "";
    },
    processFile() {
      this.loadingImageCrop = true;
      var vm = this; // vue model;
      var reader = new FileReader();

      if (this.$refs.myFiles.files && this.$refs.myFiles.files[0]) {
        reader.readAsDataURL(this.$refs.myFiles.files[0]);
        reader.onload = function (event) {
          if (event.target.readyState == FileReader.DONE) {
            vm.selectedImage = event.target.result;
            vm.loadingImageCrop = false;
          }
        };
      } else {
        alert("Algo deu errado com o upload da imagem.");
        vm.loadingImageCrop = false;
      }
    },
    getUser() {
      this.loading = true;
      http
        .get("user/details", authentication.getHeaders())
        .then((response) => {
          this.user = response.data;
          let user = authentication.getUser();
          user.info = this.user;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  position: relative;
  padding-bottom: 45px;
}

.home.container {
  width: 90%;
  max-width: initial;
}

.grid {
  position: relative;
  width: 100%;
  max-width: 580px;
  display: grid;
  grid-template-columns: 1fr;
  margin: 25px 0 0 0;
  gap: 0 3.5rem;
  @media screen and (max-width: 1139px) {
    display: block;
    max-width: initial;
  }
}

.user-image {
  position: relative;
  width: 110px;
  height: 110px;
  border-radius: 100%;
  background-color: #eee;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: -60px auto 0 auto;
  box-shadow: 0 5px 4px 0 rgba(18, 18, 44, 0.15);
}

.info-holder {
  position: relative;
  box-shadow: 0 25px 35px rgba(18, 18, 44, 0.2);
  border-radius: 25px;
  padding: 25px;
  margin-top: 25px;
  p {
    margin-top: 35px;
    font-size: 1.15em;
    text-align: center;
  }
  .btn-holder {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    .btn.border {
      border-color: var(--secondary);
      span,
      svg {
        color: var(--secondary);
      }
    }
  }
}

.invisible-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
</style>
