<template>
  <div class="ic-bg-cover">
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div v-show="loading" class="bg-cover flex-center">
        <div class="loading-spinner"></div>
      </div>
    </transition>

    <div class="ic-container">
      <p class="text-desc mt-2 text-white">{{ t("Edite sua foto") }}:</p>
      <div class="ic-image-holder mt-1">
        <img id="editableImage" :src="imageData" />
      </div>
    </div>
    <div class="ic-action-holder mt-1">
      <p @click="confirmPhoto()" class="text-desc pointer">{{ t("Pronto") }}</p>
      <p @click="cancelPhoto()" class="text-desc ic-cancel">
        {{ t("Cancelar") }}
      </p>
    </div>
  </div>
</template>
<script>
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
export default {
  name: "ImageCrop",
  props: {
    imageData: "",
    cropSettings: {
      aspectRatio: 1,
      shape: "rect",
    },
  },
  data() {
    return {
      image: "",
      cropper: Object,
      loading: false,
    };
  },
  methods: {
    confirmPhoto() {
      this.loading = true;
      setTimeout(() => {
        let croppedImage = this.cropper
          .getCroppedCanvas()
          .toDataURL("image/jpg");
        this.cropper.destroy();
        this.$emit("photoCropConfirm", croppedImage);
      }, 500);
    },
    cancelPhoto() {
      this.cropper.destroy();
      this.$emit("photoCropCancel");
    },
    loadImage() {
      if (this.imageData) {
        var image = document.getElementById("editableImage");
        image.src = this.imageData;
        this.cropper = new Cropper(image, {
          aspectRatio: this.cropSettings.aspectRatio,
          viewMode: 1, // fit canvas horizontally and vertically
          dragMode: "move",
        });
      }
      this.loading = false;
    },
  },
  watch: {
    imageData: "loadImage",
  },
};
</script>
<style>
.ic-bg-cover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #000;
  z-index: 100;
  top: 0;
  left: 0;
}

.bg-cover {
  position: fixed !important;
  width: 100vw !important;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  z-index: 9999;
}

.text-white {
  color: #fff;
}

.text-desc {
  font-family: fontRegular;
  font-size: 1.4em;
}

.ic-container {
  position: relative;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
}

.ic-action-holder {
  position: fixed;
  width: 90vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.ic-action-holder * {
  padding: 8px 14px;
  background: #3d49e7;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
}

.ic-action-holder .ic-cancel {
  background: #d32c2c;
}

.ic-image-holder {
  position: relative;
  width: 100%;
  max-height: 75vh;
  background-color: #222;
  border-radius: 3px;
}

.ic-image-holder img {
  display: block;
  max-width: 100%;
}

@media only screen and (min-width: 1140px) {
  .ic-action-holder {
    max-width: 310px;
  }
}

/* .cropper-crop.box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
} */
</style>
